import { Route, Switch } from 'react-router-dom'
import { SecureRoute, LoginCallback } from '@okta/okta-react'
import { makeStyles } from '@material-ui/core/styles'

import Alert from './Components/Alert'
import Appointments from './Components/Appointments'
import TwilioWebChat from './Components/TwilioWebChat'
import Header from './Components/Header'
import Footer from './Components/Footer'
import HomePage from './Components/HomePage'
import Profile from './Components/Profile/Profile'
import Services from './Components/Services'
import ServicePage from './Components/ServicePage'

import useAnalytics from './hooks/analytics'

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      theme.overrides && theme.overrides.body
        ? theme.overrides.body.background
        : '#fff',
    color:
      theme.overrides && theme.overrides.body
        ? theme.overrides.body.color
        : '#000',
    minHeight: '100%',
    overflowX: 'hidden',
    marginTop: theme.spacing(9),
  },
}))

const Routing = (props) => {
  useAnalytics()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Header />
      <Alert />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/implicit/callback" component={LoginCallback} />
        <Route
          path="/home"
          render={() => <HomePage oktaSettings={props.oktaSettings} />}
        />
        <SecureRoute path="/profile" component={Profile} />
        <SecureRoute path="/appointments" component={Appointments} />
        <Route path="/services" exact component={Services} />
        <Route path="/services/:service" component={ServicePage} />
      </Switch>
      <TwilioWebChat />
      <Footer />
    </div>
  )
}

export default Routing
