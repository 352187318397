import { Fragment, useContext, useState } from 'react'
import Handlebars from 'handlebars'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Collapse,
  TextField,
  Button,
  Checkbox,
} from '@material-ui/core'

import CustomerContext from '../../Context/customerContext'
import actionDefaults from '../../Context/actionDefaults'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  themeInput: {
    width: '100%',
  },
  testBtn: {
    marginTop: theme.spacing(3),
  },
}))

export default function ActionSettings(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const customerContext = useContext(CustomerContext)
  const [actionState, setActionState] = useState(customerContext.actionOverride)

  if (
    customerContext.actionOverride &&
    customerContext.actionOverride.actions &&
    isLoading === true
  ) {
    let newActions = { ...customerContext.actionOverride }
    console.log('setting default actions', newActions)
    setActionState(newActions)
    setIsLoading(false)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  const updateAction = (action, fieldName, fieldValue) => {
    let tempActionState = { ...actionState }
    tempActionState.actions.forEach((act, index, array) => {
      //console.log(action.name + ' ' + act.name)
      if (action.name === act.name) {
        array[index][fieldName] = fieldValue
      }
    })

    setActionState({ ...tempActionState })
  }

  const testAction = (action) => {
    console.log(action)
    var template = Handlebars.compile(action.overrideText)
    let demoData = customerContext.getDemoData()
    let overrideExample = template(demoData)
    updateAction(action, 'overrideExample', overrideExample)
  }

  const testWebhook = async (action) => {
    console.log(action)
    let data = customerContext.getDemoData()
    let response = await fetch(action.webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    })
    let { status } = response
    let body = await (await response.blob()).text()
    console.log(response)
    let webhookExample = status + ' ' + body
    updateAction(action, 'webhookExample', webhookExample)
  }

  const updateActionValue = (event, action) => {
    let value = event.target.value

    let tempActionState = { ...actionState }
    tempActionState.actions.forEach((act, index, array) => {
      console.log(action.name + ' ' + act.name)
      if (action.name === act.name) {
        array[index].overrideText = value
      }
    })
    setActionState({ ...tempActionState })
  }

  const updateWebhookValue = (event, action) => {
    let value = event.target.value
    updateAction(action, 'webhookUrl', value)
  }

  const updateActionsConfig = async () => {
    console.log('updating actions config')
    let result = await customerContext.updateActionConfig(actionState)
    console.log(result)
  }

  const resetActionsConfig = async () => {
    console.log('resetting actions config')
    let result = await customerContext.updateActionConfig(actionDefaults)
    console.log(result)
  }

  const actionUseSmsOverride = (event, action) => {
    let value = event.target.checked
    updateAction(action, 'override', value)
  }

  const actionUseWebhook = (event, action) => {
    let value = event.target.checked
    updateAction(action, 'webhook', value)
  }

  return (
    <Card square className={classes.card}>
      <CardHeader title="Action Configuration"></CardHeader>
      <CardContent>
        <Grid container>
          {actionState.actions.map((action) => {
            return (
              <Fragment key={action.name}>
                <Grid item xs={12} md={6}>
                  <h3>{action.name}</h3>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h4>Default</h4>
                  {action.default}
                </Grid>
                <Grid item xs={12}>
                  Use SMS Override?
                  <Checkbox
                    checked={action.override}
                    onChange={(event) => actionUseSmsOverride(event, action)}
                    color="default"
                  />
                  <Collapse in={action.override} timeout="auto" unmountOnExit>
                    <TextField
                      id={`${action.name}-override`}
                      label="SMS Override"
                      className={classes.themeInput}
                      value={action.overrideText}
                      onChange={(event) => updateActionValue(event, action)}
                    />
                    <Button
                      size="small"
                      className={classes.testBtn}
                      onClick={() => {
                        testAction(action)
                      }}
                    >
                      Test
                    </Button>
                    <br />
                    <br />
                    {action.overrideExample && (
                      <Fragment>
                        <b>Example </b>
                        <br />
                        {action.overrideExample}
                      </Fragment>
                    )}
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  Use Webhook?
                  <Checkbox
                    checked={action.webhook}
                    onChange={(event) => actionUseWebhook(event, action)}
                    color="default"
                  />
                  <Collapse in={action.webhook} timeout="auto" unmountOnExit>
                    <TextField
                      id={`${action.name}-wh-override`}
                      label="Webhook Override"
                      className={classes.themeInput}
                      value={action.webhookUrl}
                      type="url"
                      onChange={(event) => updateWebhookValue(event, action)}
                    />
                    <Button
                      size="small"
                      className={classes.testBtn}
                      onClick={() => {
                        testWebhook(action)
                      }}
                    >
                      Test
                    </Button>
                    <br />
                    <br />
                    {action.webhookExample && (
                      <Fragment>
                        <b>Example Response</b>
                        <br />
                        {action.webhookExample}
                      </Fragment>
                    )}
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <br />
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={updateActionsConfig}>
          Update
        </Button>
        <Button size="small" onClick={resetActionsConfig}>
          Reset
        </Button>
      </CardActions>
    </Card>
  )
}
