import { useState } from 'react'
import {
  AppBar,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  brandName: {
    color: theme.overrides.MuiToolbar.root.color,
    [theme.breakpoints.between(710, 845)]: {
      maxWidth: 150,
    },
  },
  logoImg: {
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 24,
    fontFamily: 'Open Sans',
  },
  link: {
    marginRight: theme.spacing(3),
  },
  authButton: {
    marginLeft: 'auto',
    minWidth: 63,
  },
  menuButton: {
    marginLeft: 'auto',
  },
}))

export default function Header() {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const { authState, oktaAuth } = useOktaAuth()
  const isDesktop = useMediaQuery('(min-width: 710px)')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleChange = (event) => {
    const path = event.currentTarget.innerText.toLowerCase()
    if (!event.currentTarget || !event.currentTarget.innerText) {
      // no route to change
      console.log('no route to change')
    } else if (path === 'home') {
      history.push('/home')
    } else if (path === 'log out') {
      oktaAuth.signOut('/')
    } else if (path === 'log in') {
      oktaAuth.signInWithRedirect()
    } else if (path === 'profile') {
      history.push('/profile')
    } else if (path === 'services') {
      history.push('/services')
    }
  }

  const navigateHome = () => {
    history.push('/')
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const desktopMenu = (
    <>
      <Link className={classes.title} onClick={navigateHome}>
        <img
          className={classes.logoImg}
          src={theme.overrides.logo.img}
          alt="logo"
        />
        <Typography className={classes.brandName} variant="h5">
          {theme.overrides.brandName}
        </Typography>
      </Link>
      <Link className={classes.link} color="inherit" onClick={handleChange}>
        Services
      </Link>
      <Link className={classes.link} color="inherit" onClick={handleChange}>
        News
      </Link>
      <Link className={classes.link} color="inherit" onClick={handleChange}>
        Visiting
      </Link>
      <Link className={classes.link} color="inherit" onClick={handleChange}>
        Connect
      </Link>
      {!authState.isAuthenticated && (
        <Button
          className={classes.authButton}
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleChange}
        >
          Log in
        </Button>
      )}
      {authState.isAuthenticated && (
        <Button
          className={classes.authButton}
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleChange}
        >
          Log out
        </Button>
      )}
    </>
  )

  const mobileMenu = (
    <>
      <Link className={classes.title} onClick={navigateHome}>
        <img
          className={classes.logoImg}
          src={theme.overrides.logo.img}
          alt="logo"
        />
        <Typography className={classes.brandName} variant="h5">
          {theme.overrides.brandName}
        </Typography>
      </Link>
      <IconButton
        edge="start"
        className={classes.menuButton}
        onClick={handleMenu}
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleChange}>Services</MenuItem>
        <MenuItem onClick={handleChange}>News</MenuItem>
        <MenuItem onClick={handleChange}>Visiting</MenuItem>
        <MenuItem onClick={handleChange}>Connect</MenuItem>
        {authState.isAuthenticated && (
          <MenuItem onClick={handleChange}>Log out</MenuItem>
        )}
        {!authState.isAuthenticated && (
          <MenuItem onClick={handleChange}>Log in</MenuItem>
        )}
      </Menu>
    </>
  )

  return (
    <AppBar position="fixed" elevation={0} color="inherit">
      <Container className={classes.container}>
        <Toolbar variant="dense" disableGutters>
          {isDesktop ? desktopMenu : mobileMenu}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
