import { useContext } from 'react'
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import CustomerContext from '../Context/customerContext'

const useStyles = makeStyles((theme) => ({
  alert: {
    top: 0,
    right: 0,
    position: 'fixed',
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      marginTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 72,
    },
  },
}))

export default function Alert() {
  const classes = useStyles()
  const customer = useContext(CustomerContext)

  if (!customer.alert || !customer.alert.isActive) {
    return <div />
  }

  return (
    <MuiAlert
      className={classes.alert}
      severity={customer.alert.type}
      onClose={(ev) => {
        customer.closeAlert()
      }}
    >
      <AlertTitle>{customer.alert.title}</AlertTitle>
      {customer.alert.message}
    </MuiAlert>
  )
}
