import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@material-ui/core'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import CachedIcon from '@material-ui/icons/Cached'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AssignmentIcon from '@material-ui/icons/Assignment'
import pSBC from 'shade-blend-color'

import { useHistory } from 'react-router-dom'

import bannerIllustration from './../images/building-illustration.svg'

const useStyles = makeStyles((theme) => ({
  attribution: {
    textAlign: 'right',
  },
  banner: {
    backgroundColor: theme.palette.primary.main,
  },
  bannerTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
  bannerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bannerImg: {
    width: '100%',
  },
  button: {
    color: theme.palette.primary.main,
  },
  content: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(14),
  },
  divider: {
    border: '1px solid #eee',
    marginBlockStart: '0.7rem',
  },
  iconBox: {
    display: 'inline-box',
    backgroundColor: pSBC(0.85, theme.palette.primary.main),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 20,
  },
  sectionTitle: {
    fontWeight: 300,
  },
}))

const services = [
  {
    title: 'Apply for Benefits',
    icon: <AssignmentIcon color="primary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Renew your Benefits',
    icon: <CachedIcon color="primary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Schedule an Appointment',
    icon: <DateRangeIcon color="primary" style={{ fontSize: 100 }} />,
    link: '/appointments',
  },
]

export default function HomePage() {
  const classes = useStyles()

  const history = useHistory()

  const handleChange = (path) => {
    if (path) history.push(path)
  }

  return (
    <>
      <div className={classes.banner}>
        <Container>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs sm className={classes.bannerItem}>
              <Typography
                variant="h2"
                color="secondary"
                className={classes.bannerTitle}
              >
                Providing you with the services you need.
              </Typography>
              <Box mt={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  disableElevation
                >
                  Learn About Our Programs
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm className={classes.bannerItem}>
              <img
                src={bannerIllustration}
                className={classes.bannerImg}
                alt="City buildings"
              />
              <div className={classes.attribution}>
                Image from{' '}
                <a
                  href="https://www.freepik.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Freepik
                </a>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Container className={classes.content}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography color="primary">POPULAR SERVICES</Typography>
          </Grid>
          <Grid item xs>
            <hr className={classes.divider} />
          </Grid>
        </Grid>

        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <div className={classes.iconBox}>
              <AccountBalanceOutlinedIcon
                color="primary"
                style={{ fontSize: 40 }}
              />
            </div>
          </Grid>
          <Grid item>
            <Typography variant="h4" className={classes.sectionTitle}>
              Providing you with services you can depend on.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={10}>
          {services.map((s) => (
            <Grid item xs={12} md key={s.title}>
              <Card raised={false} onClick={() => handleChange(s.link)}>
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                  >
                    <Grid item>{s.icon}</Grid>
                    <Grid item>
                      <Typography variant="h6" align="center">
                        {s.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}
