import { createMuiTheme } from '@material-ui/core/styles'
import pSBC from 'shade-blend-color'

import logo from '../images/owlgovlogo.png'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#008cff',
    },
    secondary: {
      main: '#ffffff',
    },
    action: {
      disabled: {
        color: '#ffffff',
      },
    },
  },
  typography: {
    fontFamily: [
      'Europa',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  status: {
    danger: 'orange',
  },
  overrides: {
    logo: {
      img: logo,
    },
    brandName: 'Owl.gov',
    body: {
      background: '#fff',
      color: '#000',
    },
    MuiAppBar: {
      root: {
        background: '#fff',
      },
    },
    MuiToolbar: {
      root: {
        color: '#008cff',
      },
    },
    MuiPaper: {
      root: {
        color: '#000',
      },
    },
    MuiButton: {
      root: {
        padding: '14px 20px',
      },
      text: {
        padding: '14px 20px',
      },
    },
  },
})

const personalizedColors = {
  mainBackground: '#008cff',
  white: '#FFFFFF',
  entryPointBackground: '#e8b403',
  primaryButtonBackground: '#e8b403',
}

export const webchatTheme = {
  MainContainer: {
    width: 320,
    bottom: 130,
  },
  EntryPoint: {
    Container: {
      background: personalizedColors.entryPointBackground,
      color: personalizedColors.white,
      bottom: 70,
      borderRadius: 36,
      overflow: 'hidden',
      fontFamily: 'Europa-Bold, sans-serif',
      '.Twilio-EntryPoint-Tagline': {
        height: 'inherit',
        fontSize: 18,
        fontWeight: 'bold',
        paddingTop: 8,
        marginLeft: 16,
        marginRight: 24,
      },
      '.Twilio-EntryPoint-IconContainer': {
        height: 32,
        '.Twilio-Icon-ArrowDown': {
          height: 32,
        },
      },
      '&:hover': {
        background: pSBC(-0.1, personalizedColors.entryPointBackground),
      },
    },
  },
  MainHeader: {
    Container: {
      background: personalizedColors.white,
      color: personalizedColors.white,
      '.Twilio-MainHeader-end': {
        width: 32,
        height: 32,
        '.Twilio-Icon-Content': {
          width: 32,
          height: 32,
        },
        '.Twilio-Icon-Content path': {
          fill: '#121c2d',
        },
      },
    },
    Logo: {
      fill: personalizedColors.white,
    },
  },
  PreEngagementCanvas: {
    Form: {
      SubmitButton: {
        background: personalizedColors.primaryButtonBackground,
        color: personalizedColors.white,
      },
    },
  },
  Chat: {
    MessageInput: {
      Container: {
        Button: {
          background: personalizedColors.primaryButtonBackground,
          color: personalizedColors.white,
        },
      },
    },
    MessageListItem: {
      FromMe: {
        FromOthers: {
          Bubble: {
            background: personalizedColors.mainBackground,
            color: personalizedColors.white,
          },
          Avatar: {
            background: personalizedColors.mainBackground,
            color: personalizedColors.white,
          },
          Header: {
            color: personalizedColors.white,
          },
        },
      },
    },
  },
}
