export const config = {
  currentDemo: 'pubsec',
  friendlyName: 'Owl Gov',
  themes: [
    {
      label: 'None',
      value: 'none',
    },
    {
      label: 'White and Blue',
      value: 'whiteAndBlue',
    },
    {
      label: 'White and Red',
      value: 'whiteAndRed',
    },
    {
      label: 'Watermelon',
      value: 'watermelon',
    },
    {
      label: 'Neon',
      value: 'neon',
    },
  ],
}
