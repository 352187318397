import { useParams } from 'react-router-dom'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Link, Typography } from '@material-ui/core'

import pSBC from 'shade-blend-color'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
  },
  card: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(14),
  },
  divider: {
    border: '1px solid #eee',
    marginBlockStart: '0.7rem',
  },
  iconBox: {
    display: 'inline-box',
    backgroundColor: pSBC(0.85, theme.palette.primary.main),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 20,
  },
  sectionTitle: {
    fontWeight: 300,
  },
}))

export default function ServicePage() {
  const classes = useStyles()
  const { service } = useParams()

  const services = {
    education: {
      title: 'Education',
      content: [
        {
          heading: 'Direct Subsidized Loans',
          text:
            'Direct Subsidized Loans are available to undergraduate students with financial need.',
        },
        {
          heading: 'Direct Unsubsidized Loans',
          text:
            'Direct Unsubsidized Loans are available to undergraduate and graduate students; there is no requirement to demonstrate financial need.',
        },
        {
          heading: 'Direct PLUS Loans',
          text:
            'The U.S. Department of Education makes Direct PLUS Loans to eligible parents and graduate or professional students through schools participating in the Direct Loan Program.',
        },
      ],
    },
  }

  return (
    <>
      <Container className={classes.content}>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <Typography variant="h2" className={classes.sectionTitle}>
              {services[service].title}
            </Typography>
            <br />
            {services[service].content.map((item) => (
              <div key={item.heading}>
                <Typography color="primary" variant="h5">
                  {item.heading}
                </Typography>
                <p>
                  {item.text} <Link>Apply now.</Link>
                </p>
              </div>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
