import { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'

import CustomerContext from '../Context/customerContext'
import actionDefaults from '../Context/actionDefaults'
import { sendText, sendEmail } from '../TwilioActions'

import EventIcon from '@material-ui/icons/Event'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import pSBC from 'shade-blend-color'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { useOktaAuth } from '@okta/okta-react'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
  },
  chipGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    marginTop: '1em',
  },
  content: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(14),
  },
  divider: {
    border: '1px solid #eee',
    marginBlockStart: '0.7rem',
  },
  iconBox: {
    display: 'inline-box',
    backgroundColor: pSBC(0.85, theme.palette.primary.main),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 20,
  },
  sectionTitle: {
    fontWeight: 300,
    textAlign: 'center',
  },
}))

const timeArray = [
  '7:00 AM',
  '8:00 AM',
  '9:30 AM',
  '11:30 AM',
  '1:00 PM',
  '2:00 PM',
  '3:30 PM',
  '4:30 PM',
]

export default function HomePage() {
  const classes = useStyles()
  const customerContext = useContext(CustomerContext)
  const [customerState, setCustomerState] = useState({
    firstName: '',
    lastName: '',
    primaryPhone: '',
    primaryEmail: '',
  })
  const [meetType, setMeetType] = useState('virtual')
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [checkState, setCheckState] = useState({
    sms: false,
    whatsapp: false,
    email: false,
    optin: false,
  })

  const [appointmentConfirmed, setAppointmentConfirmed] = useState(false)

  const [customizationState, setCustomizationState] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  if (customerContext.currentCustomization && isLoading) {
    let customization = customerContext.currentCustomization
    let newState = { ...customization }
    delete newState.actionOverride
    delete newState.themeOverride

    setCustomizationState(newState)
    setIsLoading(false)
  }
  const { authState } = useOktaAuth()

  useEffect(() => {
    setCustomerState(customerContext.customer)
  }, [customerContext])

  const [formData, setFormData] = useState({
    meetName: 'Benefits Consultation',
    first: '',
    last: '',
    phone: '',
    email: '',
  })

  function handleChange(event) {
    const { name, value } = event.target
    setFormData((prevInputData) => {
      return { ...prevInputData, [name]: value }
    })
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleCheckChange = (event) => {
    setCheckState({ ...checkState, [event.target.name]: event.target.checked })
  }

  const chipSelect = (val) => {
    setSelectedTime(val)
  }

  const quickFill = () => {
    setFormData((prevInputData) => ({
      ...prevInputData,
      first: customerState.firstName,
      last: customerState.lastName,
      phone: customerState.primaryPhone,
      email: customerState.primaryEmail,
    }))
  }

  const timeParse = () => {
    let splitTime = selectedTime.split(':')
    let hours = parseInt(splitTime[0])
    let mins = parseInt(splitTime[1].split(' ')[0])
    let timeOfDay = splitTime[1].split(' ')[1]
    if (timeOfDay === 'PM') hours += 12
    selectedDate.setHours(hours, mins)
    return selectedDate
  }

  const appointmentJSON = (date) => {
    return {
      name: formData.meetName,
      dateISO: date.toISOString(),
      friendlyDate: date.toDateString(),
      friendlyTime: selectedTime,
      description: '',
      meetingType: meetType,
      receiveSMS: checkState.sms,
      receiveWhatsApp: checkState.whatsapp,
      receiveEmail: checkState.email,
    }
  }

  const putAppointment = async (appointment) => {
    return customerContext.updateCustomizationConfig(
      {
        ...customizationState,
        appointment,
      },
      'Appointment Confirmed!',
      false,
    )
  }

  const resetActionsConfig = async () => {
    console.log('resetting actions config')
    let result = await customerContext.updateActionConfig(actionDefaults)
    console.log(result)
  }

  const sendAppointmentNotify = async (data) => {
    let actionText = null
    actionText = await customerContext.getActionText('Appointment Notification')

    if (!actionText) {
      console.log('Action text is null, need to reset')
      await resetActionsConfig()
      actionText = await customerContext.getActionText(
        'Appointment Notification',
      )
    }

    let { primaryEmail, primaryPhone } = customerContext.customer
    let { preferredSmsOutbound } = customerContext.customer.demoConfig
    if (data.receiveSMS) {
      console.log('Going to send SMS')
      let result = await sendText(
        authState.accessToken.accessToken,
        preferredSmsOutbound,
        primaryPhone,
        actionText,
      )
      console.log(result)
    }
    if (data.receiveWhatsApp) {
      console.log('Going to send Whatsapp')
      let result = await sendText(
        authState.accessToken.accessToken,
        ``,
        `whatsapp:${primaryPhone}`,
        actionText,
      )
      console.log(result)
    }
    if (data.receiveEmail) {
      console.log('Going to send Email')
      let result = await sendEmail(
        authState.accessToken.accessToken,
        primaryEmail,
        'owlGovAppointmentNotification',
        {
          govName: 'Owl Gov',
          toAddress: primaryEmail,
          appointmentName: data.name,
          appointmentDate: data.friendlyDate,
          appointmentTime: data.friendlyTime,
        },
      )
      console.log('sent email', result)
    }
    customerContext.setAlert(
      'success',
      'Appointment Created!',
      'Thank you for scheduling',
    )
  }

  const handleSubmit = async () => {
    let completeDate = timeParse()
    let appointment = appointmentJSON(completeDate)
    try {
      await putAppointment(appointment)
    } catch (err) {
      console.error('Error trying to update demo appointment data', err)
    }
    setAppointmentConfirmed(true)

    await sendAppointmentNotify(appointment)
  }

  const datePicker = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="appointment-data"
        label="Appointment Date"
        value={selectedDate}
        onChange={handleDateChange}
        minDate={new Date()}
        autoOk={true}
        KeyboardButtonProps={{
          'aria-label': 'Change Date',
        }}
      />
    </MuiPickersUtilsProvider>
  )

  const meetingTypeButtons = (
    <>
      <Button
        value="virtual"
        aria-label="virtual"
        variant="contained"
        color={meetType === 'virtual' ? 'primary' : 'default'}
        onClick={() => setMeetType('virtual')}
      >
        Virtual
      </Button>
      <Button
        value="inperson"
        aria-label="inperson"
        variant="contained"
        color={meetType === 'virtual' ? 'default' : 'primary'}
        onClick={() => setMeetType('physical')}
      >
        In-Person
      </Button>
    </>
  )

  const timeChips = timeArray.map((time) => (
    <Chip
      label={time}
      color={time === selectedTime ? 'primary' : 'default'}
      onClick={() => chipSelect(time)}
      key={time}
    />
  ))

  const contactForm = (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              id="first-name"
              label="First Name"
              name="first"
              value={formData.first}
              style={{ margin: 8 }}
              margin="normal"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              id="last-name"
              label="Last Name"
              name="last"
              value={formData.last}
              style={{ margin: 8 }}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              id="phone-number"
              label="Phone Number"
              name="phone"
              value={formData.phone}
              style={{ margin: 8 }}
              margin="normal"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              id="email-address"
              name="email"
              label="Email Address"
              value={formData.email}
              style={{ margin: 8 }}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <p>
          How would you like to be contacted regarding your appointment
          confirmation, changes, and reminders?
        </p>
        <Grid container spacing={1}>
          <Grid item sm={10}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkState.sms}
                  onChange={handleCheckChange}
                  name="sms"
                  color="primary"
                />
              }
              label="SMS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkState.whatsapp}
                  onChange={handleCheckChange}
                  name="whatsapp"
                  color="primary"
                />
              }
              label="Whatsapp"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkState.email}
                  onChange={handleCheckChange}
                  name="email"
                  color="primary"
                />
              }
              label="Email"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkState.optin}
                  onChange={handleCheckChange}
                  name="optin"
                  color="primary"
                  required={true}
                />
              }
              label="Consent to opt-in for notifications:"
            />
            <p>
              I have read and accept the{' '}
              <a
                href="https://www.twilio.com/legal/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href="https://www.twilio.com/legal/tos"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              . By selecting a notification channel, you will receive
              notifications for appointment confirmations, updates, or
              cancellations. For messaging, reply STOP to stop notifications at
              any time. For email, there will be an unsubscribe link available.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.chipGrid}>
          <Button
            variant="contained"
            startIcon={<FlashOnIcon />}
            onClick={quickFill}
          >
            Quickfill
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!checkState.optin}
          >
            Request Appointment
          </Button>
        </Grid>
      </Grid>
    </form>
  )

  const scheduleAppointmentView = (
    <Grid container spacing={2} direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h4" className={classes.sectionTitle}>
          Let's schedule your
          <TextField
            id="meeting-name"
            label="Meeting Name"
            name="meetName"
            value={formData.meetName}
            style={{ margin: 8 }}
            margin="normal"
            fullWidth
            onChange={handleChange}
          />
        </Typography>
      </Grid>
      <Grid item>{meetingTypeButtons} </Grid>
      <Grid item>{datePicker}</Grid>
      {selectedDate && (
        <Grid item className={classes.chipGrid}>
          {timeChips}
        </Grid>
      )}
      {selectedTime && contactForm}
    </Grid>
  )

  const confirmedAppointment = (
    <Grid container spacing={2} direction="column" alignItems="center">
      <br />
      <br />
      <Grid item>
        <Typography variant="h4" className={classes.sectionTitle}>
          Thanks for scheduling! <br />
          You're confirmed for{' '}
          {selectedDate &&
            selectedDate.toLocaleString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}{' '}
          at {selectedTime}
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <>
      <Container className={classes.content}>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <div className={classes.iconBox}>
              <EventIcon color="primary" style={{ fontSize: 40 }} />
            </div>
          </Grid>
          <Grid item>
            <Typography variant="h3" className={classes.sectionTitle}>
              Meet with us at your convenience.
            </Typography>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
              Now offering virtual and in-person appointments.
            </Typography>
            <hr />
          </Grid>
        </Grid>

        {!appointmentConfirmed && scheduleAppointmentView}
        {appointmentConfirmed && confirmedAppointment}
      </Container>
    </>
  )
}
