const createFetchOptions = (body) => ({
  body: JSON.stringify(body),
  headers: {
    'Content-Type': 'application/json',
  },
  method: 'POST',
})

const sendText = async (Token, fromNumber, toNumber, message) => {
  const baseUrl = process.env.REACT_APP_FUNCTION_BASE
  const url = `${baseUrl}/sendMessage`

  const body = {
    Token,
    fromNumber,
    toNumber,
    message,
  }

  try {
    let response = await fetch(url, createFetchOptions(body))
    let result = await response.json()
    console.log('sent sms', result)
    trackAction('sms')
    return result
  } catch (err) {
    console.error(err)
    trackAction('smsError')
    return false
  }
}

const sendEmail = async (Token, to, template, params) => {
  const baseUrl = process.env.REACT_APP_FUNCTION_BASE
  const url = `${baseUrl}/sendEmail`

  const body = {
    Token,
    mode: 'Transactional',
    toAddress: to,
    transType: template,
    substitutionData: JSON.stringify(params),
  }

  try {
    let response = await fetch(url, createFetchOptions(body))
    let result = await response.json()
    console.log('sent email', result)
    trackAction('email')
    return result
  } catch (err) {
    console.error(err)
    trackAction('emailError')
    return false
  }
}

const trackAction = (action, traits, externalIds) => {
  if (!process.env.REACT_APP_SEGMENT_WRITE_KEY) return
  if (!window.analytics) return

  window.analytics.track(
    action,
    {
      traits,
    },
    { externalIds },
  )
}

const identify = (userId, traits) => {
  if (!process.env.REACT_APP_SEGMENT_WRITE_KEY) return
  if (!window.analytics) return

  window.analytics.identify(`${userId}`, traits)
}

export { sendText, sendEmail, trackAction, identify }
