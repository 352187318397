import { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Container,
  Button,
} from '@material-ui/core'
import { Launch as LaunchIcon } from '@material-ui/icons'

import CustomerContext from '../../Context/customerContext'
import ThemeSettings from './ThemeSettings'
import ActionSettings from './ActionSettings'
import { config } from '../../config'
import DemoCustomizationSettings from './DemoCustomizationSettings'

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    background: '#121c2d',
    [theme.breakpoints.down('sm')]: {
      height: 48,
    },
  },
  section: {
    marginBottom: 250,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 100,
      minHeight: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 120,
    },
  },
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  activeDemoBlock: {
    display: 'inline',
  },
  warningText: {
    color: 'red',
    background: 'yellow',
    display: 'inline',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

export default function Profile() {
  const classes = useStyles()
  const [customerState, setCustomerState] = useState()
  const customerContext = useContext(CustomerContext)

  if (!customerState && !customerContext.customer) {
    return <div>loading...</div>
  }

  const updateActiveDemo = async () => {
    let result = await customerContext.updateActiveDemo()
    console.log('updated active demo', result)
  }

  const customerRecordClicked = () => {
    const url = 'https://home.twiliodemo.com/customer'
    var win = window.open(url, '_blank')
    win.focus()
  }

  if (!customerState) {
    console.log('setting customer state')
    setCustomerState(customerContext.customer)

    return <div>loading...</div>
  } else {
    console.log('using old customer state')
  }

  let prefferedOutbound = '+17818083539'
  if (customerState.demoConfig && customerState.demoConfig.prefferedOutbound) {
    prefferedOutbound = customerState.demoConfig.prefferedOutbound
  }

  console.log('current customer', customerState)

  const { name, primaryEmail, primaryPhone } = customerState
  const { activeDemo } = customerState.demoConfig

  let activeDemoBlock = <span>{activeDemo}</span>

  if (!activeDemo || activeDemo !== config.currentDemo) {
    activeDemoBlock = (
      <div className={classes.activeDemoBlock}>
        <div className={classes.warningText}>{activeDemo}</div>
        <Button
          className={classes.activeDemoBtn}
          size="small"
          onClick={updateActiveDemo}
        >
          Update to {config.friendlyName}
        </Button>
      </div>
    )
  }

  return (
    <section className={classes.section}>
      <Container>
        <Card square className={classes.card}>
          <CardHeader
            title="Customer Information"
            action={<LaunchIcon onClick={customerRecordClicked} />}
          ></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                Customer Name: {name}
              </Grid>
              <Grid item xs={12} md={6}>
                Customer Email: {primaryEmail}
              </Grid>
              <Grid item xs={12} md={6}>
                Customer Phone: {primaryPhone}
              </Grid>
              <Grid item xs={12} md={6}>
                Preferred Outbound Number: {prefferedOutbound}
              </Grid>
              <Grid item xs={12} md={6}>
                Active Demo: {activeDemoBlock}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DemoCustomizationSettings />
        <ThemeSettings />
        <ActionSettings />
        <Card square className={classes.card}>
          <CardHeader title="Demo Configuration"></CardHeader>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={6}>
                <h4>Current Demo Data</h4>
                <div>
                  <pre>
                    {JSON.stringify(customerContext.getDemoData(), null, 2)}
                  </pre>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </section>
  )
}
