import { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Collapse,
  TextField,
  Button,
  Checkbox,
  Select,
  MenuItem,
} from '@material-ui/core'

import CustomerContext from '../../Context/customerContext'
import { config } from '../../config'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  themeInput: {
    width: '100%',
  },
}))

const BRAND_MAX_CHARS = 26
const BRAND_DEFAULT_HELP_TEXT = 'Max. 26 characters'

export default function ThemeSettings() {
  const classes = useStyles()
  const [themeState, setThemeState] = useState({
    useOverrideTheme: false,
    selectedTheme: 'none',
  })
  const [isLoading, setIsLoading] = useState(true)
  const [brandHelperText, setBrandHelperText] = useState(
    BRAND_DEFAULT_HELP_TEXT,
  )
  const customerContext = useContext(CustomerContext)

  useEffect(() => {
    if (themeState.brandName) {
      const helperText =
        themeState.brandName.length === BRAND_MAX_CHARS
          ? 'Characters limit reached'
          : `${
              BRAND_MAX_CHARS - themeState.brandName.length
            } characters remaining`

      setBrandHelperText(helperText)
    } else setBrandHelperText(BRAND_DEFAULT_HELP_TEXT)
  }, [themeState.brandName])

  if (
    customerContext.customer &&
    customerContext.customer.demoConfig &&
    customerContext.customer.demoConfig.jCurrentCustomization &&
    isLoading === true
  ) {
    let customization =
      customerContext.customer.demoConfig.jCurrentCustomization
    let newTheme = { ...customization.themeOverride }
    console.log('setting default theme', newTheme)
    setThemeState(newTheme)
    setIsLoading(false)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  const updateThemeValue = (event) => {
    let newTheme = themeState
    if (event.target.id === 'logo-override') {
      newTheme.logo = event.target.value
    } else if (event.target.id === 'brandName-override') {
      newTheme.brandName = event.target.value
    } else if (event.target.id === 'headerBackgroundColor-override') {
      newTheme.headerBackgroundColor = event.target.value
    } else if (event.target.id === 'headerColor-override') {
      newTheme.headerColor = event.target.value
    } else if (event.target.id === 'primaryBackgroundColor-override') {
      newTheme.bodyBackgroundColor = event.target.value
    } else if (event.target.id === 'primaryColor-override') {
      newTheme.bodyColor = event.target.value
    } else if (event.target.id === 'btnBackgroundColor-override') {
      newTheme.btnBackgroundColor = event.target.value
    } else if (event.target.id === 'btnColor-override') {
      newTheme.btnColor = event.target.value
    } else if (event.target.id === 'paperBodyBackgroundColor-override') {
      newTheme.paperBodyBackgroundColor = event.target.value
    } else if (event.target.id === 'paperBodyColor-override') {
      newTheme.paperBodyColor = event.target.value
    }
    setThemeState({ ...newTheme })
  }

  const resetThemeConfig = async () => {
    let result = await customerContext.updateThemeConfig({})
    console.log('theme reset', result)
  }

  const updateThemeConfig = async () => {
    let result = await customerContext.updateThemeConfig(themeState)
    console.log('updated theme result', result)
  }

  const changeSelectedTheme = (event) => {
    console.log(event.target.value)
    let newTheme = { ...themeState }
    newTheme.selectedTheme = event.target.value
    setThemeState(newTheme)
  }

  let {
    logo,
    brandName,
    useOverrideTheme,
    selectedTheme,
    headerBackgroundColor,
    headerColor,
    bodyBackgroundColor,
    bodyColor,
    btnBackgroundColor,
    btnColor,
    paperBodyBackgroundColor,
    paperBodyColor,
  } = themeState

  return (
    <Card square className={classes.card}>
      <CardHeader title="Theme Configuration"></CardHeader>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id="logo-override"
              label="Logo URL Override"
              className={classes.themeInput}
              value={logo}
              onChange={updateThemeValue}
            />
          </Grid>
          <Grid item xs={false} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="brandName-override"
              label="Brand Name Override (replaces Owl.gov)"
              className={classes.themeInput}
              inputProps={{ maxLength: BRAND_MAX_CHARS }}
              helperText={brandHelperText}
              value={brandName}
              onChange={updateThemeValue}
            />
          </Grid>
          <Grid item xs={false} md={6}></Grid>
          <Grid item xs={12} md={3}>
            Prebuilt themes
          </Grid>
          <Grid item xs={12} md={9}>
            <Select
              id="theme-select"
              value={selectedTheme}
              onChange={changeSelectedTheme}
              defaultValue="none"
            >
              {config.themes.map((th) => (
                <MenuItem key={th.value} value={th.value}>
                  {th.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            Customized Color Overrides?
            <Checkbox
              checked={useOverrideTheme}
              onChange={(event) => {
                setThemeState({
                  ...themeState,
                  useOverrideTheme: event.target.checked,
                })
              }}
              color="default"
            />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={useOverrideTheme} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="headerBackgroundColor-override"
                    label="Header Background Color"
                    className={classes.themeInput}
                    value={headerBackgroundColor}
                    onChange={updateThemeValue}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="headerColor-override"
                    label="Header Color Override"
                    className={classes.themeInput}
                    value={headerColor}
                    onChange={updateThemeValue}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="primaryBackgroundColor-override"
                    label="Primary Background Color Override"
                    className={classes.themeInput}
                    value={bodyBackgroundColor}
                    onChange={updateThemeValue}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="primaryColor-override"
                    label="Primary Color Override"
                    className={classes.themeInput}
                    value={bodyColor}
                    onChange={updateThemeValue}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="btnBackgroundColor-override"
                    label="Button Background Color Override"
                    className={classes.themeInput}
                    value={btnBackgroundColor}
                    onChange={updateThemeValue}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="btnColor-override"
                    label="Button Color Override"
                    className={classes.themeInput}
                    value={btnColor}
                    onChange={updateThemeValue}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="paperBodyBackgroundColor-override"
                    label="Paper Body Background Color Override"
                    className={classes.themeInput}
                    value={paperBodyBackgroundColor}
                    onChange={updateThemeValue}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="paperBodyColor-override"
                    label="Paper Body Color Override"
                    className={classes.themeInput}
                    value={paperBodyColor}
                    onChange={updateThemeValue}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={updateThemeConfig}>
          Update
        </Button>
        <Button size="small" onClick={resetThemeConfig}>
          Reset
        </Button>
      </CardActions>
    </Card>
  )
}
