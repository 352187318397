import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core'

import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import GavelIcon from '@material-ui/icons/Gavel'
import HealingIcon from '@material-ui/icons/Healing'
import HomeIcon from '@material-ui/icons/Home'
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore'
import PublicIcon from '@material-ui/icons/Public'
import SchoolIcon from '@material-ui/icons/School'
import SubwayIcon from '@material-ui/icons/Subway'
import pSBC from 'shade-blend-color'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
  },
  card: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(14),
  },
  divider: {
    border: '1px solid #eee',
    marginBlockStart: '0.7rem',
  },
  grid: {
    minWidth: '250px',
  },
  iconBox: {
    display: 'inline-box',
    backgroundColor: pSBC(0.85, theme.palette.primary.main),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 20,
  },
  sectionTitle: {
    fontWeight: 300,
  },
}))

const services = [
  {
    title: 'Education',
    icon: <SchoolIcon color="secondary" style={{ fontSize: 100 }} />,
    path: '/education',
  },
  {
    title: 'Food',
    icon: <LocalGroceryStoreIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Healthcare',
    icon: <HealingIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'DMV',
    icon: <DirectionsCarIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Public Transit',
    icon: <SubwayIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Utilities',
    icon: <FlashOnIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Bills & Taxes',
    icon: <AttachMoneyIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Employment',
    icon: <BusinessCenterIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Housing',
    icon: <HomeIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Justice',
    icon: <GavelIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Environment',
    icon: <PublicIcon color="secondary" style={{ fontSize: 100 }} />,
  },
  {
    title: 'Recreation',
    icon: <DirectionsBikeIcon color="secondary" style={{ fontSize: 100 }} />,
  },
]

export default function Services() {
  const classes = useStyles()

  return (
    <>
      <Container className={classes.content}>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <Typography variant="h4" className={classes.sectionTitle}>
              Services
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" spacing={10}>
          {services.map((s) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              lg={3}
              key={s.title}
              className={classes.grid}
            >
              <Link
                component={RouterLink}
                to={s.path ? `/services` + s.path : `/services`}
              >
                <Card raised={false} className={classes.card}>
                  <CardContent>
                    <Grid
                      container
                      spacing={4}
                      direction="column"
                      alignItems="center"
                    >
                      <Grid item>{s.icon}</Grid>
                      <Grid item>
                        <Typography variant="h6" align="center">
                          {s.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}
