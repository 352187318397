import { BrowserRouter as Router } from 'react-router-dom'
import { OktaAuth } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import Rollbar from 'rollbar'
import { CustomerContextProvider } from './Context/customerContext'
import './App.css'
import Routing from './Routing'

const oktaSettings = new OktaAuth({
  domain: process.env.REACT_APP_OKTADOMAIN,
  clientId: process.env.REACT_APP_OKTACLIENTID,
  baseUrl: `https://${process.env.REACT_APP_OKTADOMAIN}`,
  issuer: `https://${process.env.REACT_APP_OKTADOMAIN}/oauth2/default`,
  redirectUri: window.location.origin + '/implicit/callback',
  pkce: true,
})

const App = () => {
  let state = {}

  if (process.env.REACT_APP_ROLLBAR_TOKEN) {
    state = {
      rollbar: new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: process.env.REACT_APP_ROLLBAR_ENV,
      }),
    }
    window.rollbar = state.rollbar
  }

  return (
    <Router>
      <Security oktaAuth={oktaSettings} style={{ height: 'inherit' }}>
        <CustomerContextProvider rollbar={state.rollbar}>
          <Routing oktaSettings={oktaSettings} />
        </CustomerContextProvider>
      </Security>
    </Router>
  )
}

export default App
