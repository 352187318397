import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import Segment from '@segment/analytics.js-core/build/analytics'
import SegmentIntegration from '@segment/analytics.js-integration-segmentio'

import CustomerContext from '../Context/customerContext'
import { LS_SEGMENT_KEY } from '../constants'

const apiKey = process.env.REACT_APP_SEGMENT_KEY

const useAnalytics = () => {
  const [analytics, setAnalytics] = useState(null)
  const customerContext = useContext(CustomerContext)

  const { demoData } = customerContext.getDemoData()

  const segmentWriteKey = localStorage.getItem(LS_SEGMENT_KEY)
  const location = useLocation()

  if (process.env.REACT_APP_GA) ReactGA.initialize(process.env.REACT_APP_GA)

  useEffect(() => {
    if (analytics) analytics.page()
  }, [location, location.pathname, analytics])

  useEffect(() => {
    if (demoData?.segmentEnabled) {
      if (!apiKey && !segmentWriteKey) return

      if (!analytics) {
        const segment = new Segment()
        segment.use(SegmentIntegration)
        segment.initialize({
          'Segment.io': {
            apiKey: segmentWriteKey || apiKey,
          },
        })

        setAnalytics(segment)
        window.analytics = segment
      }
    } else {
      if (analytics) {
        setAnalytics(null)
        window.analytics = null
      }

      return
    }
  }, [demoData, segmentWriteKey, analytics])
}

export default useAnalytics
