const actionDefaults = {
  actions: [
    {
      name: 'Appointment Notification',
      override: false,
      overrideText: '',
      overrideExample: '',
      webhook: false,
      webhookUrl: '',
      webhookResponse: '',
      default: `Your {{demoData.appointment.name}} appointment is confirmed for {{demoData.appointment.friendlyDate}} at {{demoData.appointment.friendlyTime}}. Reply 'reschedule' to change.`,
    },
  ],
}

export default actionDefaults
