import { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Link, Toolbar } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { config } from '../config'

import CustomerContext from '../Context/customerContext'

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  toolbar: {
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
    },
  },
  ul: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    justifyContent: 'space-between',
    marginLeft: -1,
    listStyle: 'none',
    paddingInlineStart: 0,
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    },
  },
  li: {
    flexGrow: 1,
    flexBasis: 'auto',
    margin: '.25em 0',
    textAlign: 'center',
    borderLeft: '1px solid #fff',
    [theme.breakpoints.down('sm')]: {
      padding: '0 .25em',
      fontSize: 12,
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 1em',
      fontSize: 14,
    },
  },
}))

export default function Footer() {
  const classes = useStyles()
  const { authState } = useOktaAuth()
  const customerContext = useContext(CustomerContext)
  let [preferredOutbound, setPreferredOutbound] = useState(' ')
  let [preferredSmsOutbound, setPreferredSmsOutbound] = useState(' ')

  useEffect(() => {
    if (authState.isAuthenticated) {
      setPreferredOutbound(
        customerContext.customer?.demoConfig?.preferredOutbound
          ? customerContext.customer.demoConfig.preferredOutbound
          : '',
      )
      setPreferredSmsOutbound(
        customerContext.customer?.demoConfig?.preferredSmsOutbound
          ? customerContext.customer.demoConfig.preferredSmsOutbound
          : '',
      )
    }
  }, [customerContext, authState.isAuthenticated])

  function contactUs() {
    let contactText = ''
    if (preferredOutbound === preferredSmsOutbound) {
      contactText = 'Contact Us by Phone or SMS: ' + preferredOutbound
    } else {
      contactText = 'Contact Us: '
      if (preferredOutbound) {
        contactText += ' Phone: ' + preferredOutbound
      }
      if (preferredSmsOutbound) {
        contactText += ' SMS: ' + preferredSmsOutbound
      }
    }
    return contactText
  }

  return (
    <AppBar position="fixed" className={classes.appBar} color="inherit">
      <Toolbar className={classes.toolbar} disableGutters={true}>
        <ul className={classes.ul}>
          <li className={classes.li}>{config.friendlyName} &copy;</li>
          <li className={classes.li}>
            <Link component={RouterLink} to="/profile" color="inherit">
              Demo Set Up
            </Link>
          </li>

          <li className={classes.li}>Privacy Policy</li>
          {authState.isAuthenticated && (
            <li className={classes.li}>{contactUs()}</li>
          )}
        </ul>
      </Toolbar>
    </AppBar>
  )
}
