import { useContext, useState, useEffect } from 'react'
import * as FlexWebChat from '@twilio/flex-webchat-ui'

import logo from '../images/owlgovlogo.png'
import CustomerContext from '../Context/customerContext'

import { webchatTheme } from '../Themes/default'

export default function TwilioWebChat() {
  const { customer } = useContext(CustomerContext)

  const [loading, setLoading] = useState(false)
  const [manager, setManager] = useState()

  useEffect(() => {
    if (
      !manager &&
      !loading &&
      customer?.primaryPhone &&
      process.env.REACT_APP_TWILIOACCOUNTSID &&
      process.env.REACT_APP_FLEXFLOWSID
    ) {
      setLoading(true)
      const { primaryPhone, flexWorker, name } = customer

      FlexWebChat.MainHeader.defaultProps.imageUrl = logo
      FlexWebChat.MainHeader.defaultProps.titleText = ''

      const configuration = {
        accountSid: process.env.REACT_APP_TWILIOACCOUNTSID,
        flexFlowSid: process.env.REACT_APP_FLEXFLOWSID,
        startEngagementOnInit: false,
        markdownSupport: true,
        fileAttachment: {
          enabled: true,
        },
        colorTheme: {
          overrides: webchatTheme,
        },
        context: {
          topic: 'General',
          worker: flexWorker,
        },
        preEngagementConfig: {
          description: 'Hey there!',
          fields: [
            {
              label: 'What is your name?',
              type: 'InputItem',
              attributes: {
                name: 'friendlyName',
                type: 'text',
                placeholder: 'Name',
                required: false,
                value: name,
              },
            },
            {
              label: 'What is your phone number?',
              type: 'InputItem',
              attributes: {
                name: 'customerNumber',
                type: 'text',
                placeholder: 'Number',
                required: false,
                value: primaryPhone,
              },
            },
            {
              label: 'How can we help you?',
              type: 'TextareaItem',
              attributes: {
                name: 'question',
                type: 'text',
                placeholder: 'Type your question here',
                required: false,
                rows: 5,
              },
            },
          ],
          submitLabel: "Ok Let's Go!",
        },
      }

      console.log('webchat config', configuration)

      FlexWebChat.createWebChat(configuration).then((webchat) => {
        const { manager } = webchat

        // on engagement started, send the question into the chat channel
        // this allows saying agent, to skip to all ivr
        FlexWebChat.Actions.on('afterStartEngagement', (payload) => {
          const { question } = payload.formData
          if (!question) {
            return
          }

          const { channelSid } = manager.store.getState().flex.session

          manager.chatClient
            .getChannelBySid(channelSid)
            .then((channel) => channel.sendMessage(question))
        })

        manager.strings.PredefinedChatMessageAuthorName = 'Owl Gov'
        manager.strings.PredefinedChatMessageBody =
          'Hi there! How can we help you today?'

        setManager(manager)
        console.log('set webchat manager', manager)
      })
    }
  }, [manager, customer, loading])

  if (manager) {
    return (
      <FlexWebChat.ContextProvider manager={manager}>
        <FlexWebChat.RootContainer />
      </FlexWebChat.ContextProvider>
    )
  } else {
    return <div />
  }
}
