import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Button,
  Switch,
  TextField,
  FormControlLabel,
} from '@material-ui/core'

import CustomerContext from '../../Context/customerContext'
import { LS_SEGMENT_KEY } from '../../constants'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  switch: {
    display: 'flex',
    alignItems: 'end',
  },
}))

const defaultCustomization = {
  segmentEnabled: false,
}
export default function DemoCustomizationSettings() {
  const classes = useStyles()
  const [customizationState, setCustomizationState] = useState(
    defaultCustomization,
  )
  const [isLoading, setIsLoading] = useState(true)
  const customerContext = useContext(CustomerContext)

  const [segmentWriteKey, setSegmentWriteKey] = useState(
    localStorage.getItem(LS_SEGMENT_KEY) ?? '',
  )

  if (customerContext.currentCustomization && isLoading) {
    let customization = customerContext.currentCustomization
    let newState = { ...customizationState, ...customization }
    delete newState.actionOverride
    delete newState.themeOverride

    setCustomizationState(newState)
    setIsLoading(false)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  const updateSegmentEnabled = (event) => {
    setCustomizationState({
      ...customizationState,
      segmentEnabled: event.target.checked,
    })
  }

  const updateSegmentWriteKey = (event) =>
    setSegmentWriteKey(event.target.value)

  const resetCustomizationConfig = async () => {
    let result = await customerContext.updateCustomizationConfig({
      segmentEnabled: false,
    })

    setCustomizationState({
      ...customizationState,
      segmentEnabled: false,
    })

    console.log('Customization reset', result)

    localStorage.removeItem(LS_SEGMENT_KEY)
    setSegmentWriteKey('')

    // Forcing page reload so that segment get
    // instantiated with the proper write key
    window.location.reload()
  }

  const updateCustomizationConfig = async () => {
    let result = await customerContext.updateCustomizationConfig(
      customizationState,
    )

    localStorage.setItem(LS_SEGMENT_KEY, segmentWriteKey)

    console.log('updated customization result', result)

    // Forcing page reload so that segment get
    // instantiated with the proper write key
    window.location.reload()
  }

  let { segmentEnabled } = customizationState

  return (
    <Card square className={classes.card}>
      <CardHeader title="Demo Customization"></CardHeader>
      <CardContent>
        <Grid container spacing={2}>
          <Grid className={classes.switch} item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Switch
                  id="segmentEnabled"
                  color="primary"
                  checked={segmentEnabled}
                  onChange={updateSegmentEnabled}
                />
              }
              label="Segment Tracking"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="segmentWriteKey"
              label="Segment Write Key Override"
              placeholder="••••••••••••••••••••••••••••••••"
              type="text"
              value={segmentWriteKey}
              onChange={updateSegmentWriteKey}
              disabled={!segmentEnabled}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={updateCustomizationConfig}>
          Update
        </Button>
        <Button size="small" onClick={resetCustomizationConfig}>
          Reset
        </Button>
      </CardActions>
    </Card>
  )
}
