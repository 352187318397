import { createContext, Component } from 'react'
import { withOktaAuth } from '@okta/okta-react'
import Handlebars from 'handlebars'
import {
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from '@material-ui/core/styles'
import pSBC from 'shade-blend-color'

import { theme } from '../Themes/default'
import actionDefaults from '../Context/actionDefaults'

import { config } from '../config'

const merge = require('deepmerge')
const CustomerContext = createContext()

// Change the default to not clash with webchat
const generateClassName = createGenerateClassName({
  productionPrefix: 'wjss',
})

class CustomerContextProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closeAlert: this.closeAlert,
      getDemoData: this.getDemoData,
      updateCustomizationConfig: this.updateCustomizationConfig,
      updateThemeConfig: this.updateThemeConfig,
      updateActionConfig: this.updateActionConfig,
      updateActiveDemo: this.updateActiveDemo,
      getActionText: this.getActionText,
      setAlert: this.setAlert,
      alert: {
        isActive: false,
      },
      rollbar: props.rollbar,
      themeOverride: {},
      computedTheme: theme,
      actionOverride: {},
    }
    this.fetchCustomer()
  }

  async getAuthToken() {
    return this.props.authState.accessToken.accessToken
  }

  getRequestHeaders(authToken) {
    return {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }

  async updateCustomerConfig(body) {
    let authToken = await this.getAuthToken()
    const newDemoConfig = await fetch(
      process.env.REACT_APP_USERAPI +
        '/api/DemoConfiguration/' +
        this.state.customer.id,
      {
        headers: {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(body),
      },
    )
    return await newDemoConfig.json()
  }

  async fetchCustomer() {
    const { authState } = this.props
    if (authState?.isAuthenticated) {
      const user = await this.props.oktaAuth.getUser()
      let authToken = await this.getAuthToken()
      try {
        const response = await fetch(
          process.env.REACT_APP_USERAPI +
            '/api/Customer?email=' +
            encodeURIComponent(user.email.replace('+flex', '')),
          {
            headers: this.getRequestHeaders(authToken),
          },
        )
        let customer = await response.json()

        console.log('setting customer', customer)
        let actions = actionDefaults
        let currentCustomization = {}
        //if their activeDemo is set correctly, we have our config
        //otherwise fetch by current demo name
        if (
          customer.demoConfig &&
          customer.demoConfig.activeDemo === config.currentDemo &&
          customer.demoConfig.jCurrentCustomization
        ) {
          currentCustomization = customer.demoConfig.jCurrentCustomization
        } else {
          const configResponse = await fetch(
            process.env.REACT_APP_USERAPI +
              `/api/DemoConfiguration/${customer.id}/${config.currentDemo}`,
            {
              headers: this.getRequestHeaders(authToken),
            },
          )
          let currentDemoConfig = await configResponse.json()
          if (
            currentDemoConfig.demoConfig &&
            currentDemoConfig.demoConfig.jCurrentCustomization
          ) {
            currentCustomization =
              currentDemoConfig.demoConfig.jCurrentCustomization
          }
        }

        let { themeOverride, actionOverride } = currentCustomization
        if (themeOverride || actionOverride) {
          let theme = this.generateThemeOverrides(themeOverride)
          let computedTheme = await this.computeTheme(theme)

          if (actionOverride && actionOverride.actions) {
            actions = actionOverride
          }

          this.setState({
            customer,
            currentCustomization,
            computedTheme,
            themeOverride: theme,
            actionOverride: actions,
          })
        } else {
          this.setState({
            customer,
            currentCustomization,
            actionOverride: actions,
          })
        }
      } catch (err) {
        console.error(err)
        if (this.state.rollbar) {
          this.state.rollbar.error('fetch customer error', err)
        }
      }
    } else if (authState.isPending) {
      setTimeout(() => {
        this.fetchCustomer()
      }, 300)
    }
  }

  getDemoData = () => {
    if (!this.state.customer) return {}
    let demoData = {
      ...this.state.customer.demoConfig.jCurrentCustomization,
    }
    delete demoData.themeOverride
    delete demoData.actionOverride
    let customer = { ...this.state.customer }
    delete customer.demoConfig
    return { demoData, customer }
  }

  updateActiveDemo = async () => {
    if (!this.state.customer) {
      console.log('no customer to update activeDemo')
      return
    }
    const body = {
      activeDemo: config.currentDemo,
      id: this.state.customer.id,
    }
    let demoConfig = await this.updateCustomerConfig(body)
    console.log('setting demoConfig', demoConfig)
    let cust = this.state.customer
    cust.demoConfig = demoConfig
    this.setState({
      customer: cust,
      alert: {
        isActive: true,
        type: 'success',
        title: 'Updated!',
        message: 'Active Demo updated!',
      },
    })
    return demoConfig
  }

  closeAlert = () => {
    let currAlert = this.state.alert
    currAlert.isActive = false
    this.setState({ alert: currAlert })
  }

  updateCustomizationConfig = async (conf, alertText) => {
    if (!this.state.customer) {
      console.log('no customer to update customization')
      return
    }
    let customization = {
      ...conf,
      themeOverride: { ...this.state.themeOverride },
      actionOverride: { ...this.state.actionOverride },
    }
    let body = {
      id: this.state.customer.id,
      activeDemo: config.currentDemo,
      currentDemoCustomization: JSON.stringify(customization),
    }
    let tempResult = await this.updateCustomerConfig(body)
    let newCustomer = { ...this.state.customer }
    newCustomer.demoConfig = { ...tempResult }
    let alertTitle = alertText ? alertText : 'Customization was updated!'
    this.setState((state) => ({
      customer: newCustomer,
      alert: {
        isActive: true,
        type: 'success',
        title: alertTitle,
      },
    }))
    return tempResult
  }

  updateThemeConfig = async (theme) => {
    if (!this.state.customer) {
      console.log('no customer to update theme')
      return
    }

    let customization = {
      ...this.state.customer.demoConfig.jCurrentCustomization,
      themeOverride: { ...theme },
      actionOverride: { ...this.state.actionOverride },
    }

    let body = {
      id: this.state.customer.id,
      activeDemo: config.currentDemo,
      currentDemoCustomization: JSON.stringify(customization),
    }

    let tempResult = await this.updateCustomerConfig(body)
    let newCustomer = { ...this.state.customer }
    newCustomer.demoConfig = { ...tempResult }
    let themeOverrides = this.generateThemeOverrides(
      tempResult.jCurrentCustomization.themeOverride,
    )
    let computedTheme = await this.computeTheme(themeOverrides)
    this.setState((state) => ({
      customer: newCustomer,
      alert: {
        isActive: true,
        type: 'success',
        title: 'Theme was updated!',
        message: 'Updating page to take effect...',
      },
      themeOverrides,
      computedTheme,
    }))
    return tempResult
  }

  updateActionConfig = async (override) => {
    if (!this.state.customer) {
      console.log('no customer to update actions')
      return
    }

    override.actions.forEach((act, index, arr) => {
      delete arr[index].overrideExample
      delete arr[index].webhookExample
    })

    let customization = {
      ...this.state.customer.demoConfig.jCurrentCustomization,
      actionOverride: { ...override },
      themeOverride: { ...this.state.themeOverride },
    }

    let body = {
      id: this.state.customer.id,
      activeDemo: config.currentDemo,
      currentDemoCustomization: JSON.stringify(customization),
    }

    let tempResult = await this.updateCustomerConfig(body)
    let newCustomer = { ...this.state.customer }
    newCustomer.demoConfig = { ...tempResult }
    let actionOverride = tempResult.jCurrentCustomization.actionOverride
    this.setState((state) => ({
      customer: newCustomer,
      alert: {
        isActive: true,
        type: 'success',
        title: 'Actions were updated!',
        message: 'Try your new override to verify...',
      },
      actionOverride,
    }))
    return tempResult
  }

  generateThemeOverrides = (theme) => {
    let themeOverride = {
      body: {},
      MuiAppBar: {
        root: {},
      },
      MuiToolbar: {
        root: {},
      },
      MuiSvgIcon: {
        root: {},
      },
      MuiPaper: {
        root: {},
      },
      MuiButton: {
        root: {
          '&:hover': {},
        },
        contained: {
          '&:hover': {},
        },
        containedSecondary: {
          '&:hover': {},
        },
        outlined: {
          '&:hover': {},
        },
        textSizeSmall: {
          '&:hover': {},
        },
      },
      MuiCardContent: {
        root: {},
      },
      MuiStepIcon: {
        root: {},
        active: {},
      },
    }

    if (!theme) {
      return themeOverride
    }

    if (
      theme.selectedTheme &&
      theme.selectedTheme.length > 0 &&
      theme.selectedTheme !== 'none'
    ) {
      themeOverride.selectedTheme = theme.selectedTheme
    }

    if (theme.logo) {
      themeOverride.logo = {
        img: theme.logo,
      }
    }

    if (theme.brandName) {
      themeOverride.brandName = theme.brandName
    }

    if (theme.useOverrideTheme) {
      themeOverride.useOverrideTheme = theme.useOverrideTheme
    } else {
      console.log('new themeOverride', themeOverride)
      return themeOverride
    }

    if (theme.bodyColor) {
      themeOverride.body.color = theme.bodyColor
    }

    if (theme.bodyBackgroundColor) {
      themeOverride.body.background = theme.bodyBackgroundColor
    }

    if (theme.headerBackgroundColor) {
      themeOverride.MuiAppBar.root.background = theme.headerBackgroundColor
    }

    if (theme.headerColor) {
      themeOverride.MuiToolbar.root.color = theme.headerColor
      themeOverride.MuiSvgIcon.root.color = theme.headerColor
    }

    if (theme.paperBodyColor) {
      themeOverride.MuiPaper.root.color = theme.paperBodyColor
      themeOverride.MuiCardContent.root.color = theme.paperBodyColor
    }

    if (theme.paperBodyBackgroundColor) {
      themeOverride.MuiPaper.root.backgroundColor =
        theme.paperBodyBackgroundColor

      themeOverride.MuiCardContent.root.backgroundColor =
        theme.paperBodyBackgroundColor
    }

    if (theme.btnColor) {
      themeOverride.MuiButton.root.color = theme.btnColor
      themeOverride.MuiButton.contained.color = theme.btnColor
      themeOverride.MuiButton.containedSecondary.color = theme.btnColor
      themeOverride.MuiButton.outlined.color = theme.btnColor
      themeOverride.MuiButton.textSizeSmall.color = theme.btnColor
    }

    if (theme.btnBackgroundColor) {
      const bgHoverColor = pSBC(-0.2, theme.btnBackgroundColor)
      themeOverride.MuiButton.root.backgroundColor = theme.btnBackgroundColor
      themeOverride.MuiButton.root['&:hover'].backgroundColor = bgHoverColor

      themeOverride.MuiButton.contained.backgroundColor =
        theme.btnBackgroundColor
      themeOverride.MuiButton.contained[
        '&:hover'
      ].backgroundColor = bgHoverColor

      themeOverride.MuiButton.containedSecondary.backgroundColor =
        theme.btnBackgroundColor
      themeOverride.MuiButton.containedSecondary[
        '&:hover'
      ].backgroundColor = bgHoverColor

      themeOverride.MuiButton.outlined.backgroundColor =
        theme.btnBackgroundColor
      themeOverride.MuiButton.outlined['&:hover'].backgroundColor = bgHoverColor

      themeOverride.MuiButton.textSizeSmall.backgroundColor =
        theme.btnBackgroundColor
      themeOverride.MuiButton.textSizeSmall[
        '&:hover'
      ].backgroundColor = bgHoverColor

      themeOverride.MuiStepIcon.root.fill = theme.btnBackgroundColor
      themeOverride.MuiStepIcon.active.fill = bgHoverColor
    }

    console.log('new themeOverride', themeOverride)
    return themeOverride
  }

  computeTheme = async (themeOverrides) => {
    let computedTheme = { ...theme }

    if (themeOverrides.selectedTheme) {
      try {
        const tempTheme = await import(
          `../Themes/${themeOverrides.selectedTheme}`
        )
        computedTheme = { ...tempTheme.theme }
      } catch (err) {
        console.log('error loading prebuilt theme', err)
      }
    }

    if (themeOverrides && Object.keys(themeOverrides).length > 0) {
      computedTheme.overrides = merge(computedTheme.overrides, themeOverrides)
    }

    console.log('setting theme', computedTheme)
    return computedTheme
  }

  getActionText = async (actionName) => {
    if (!this.state.customer || !this.state.actionOverride) {
      console.log('no customer or action data set')
      return
    }
    let foundText = ''

    this.state.actionOverride.actions.forEach((action) => {
      if (action.name === actionName) {
        let demoData = this.getDemoData()
        if (action.override) {
          let template = Handlebars.compile(action.overrideText)
          foundText = template(demoData)
        } else {
          let template = Handlebars.compile(action.default)
          foundText = template(demoData)
        }
      }
    })
    return foundText
  }

  setAlert = (type, title, message) => {
    this.setState({
      alert: {
        isActive: true,
        type,
        title,
        message,
      },
    })
  }

  render() {
    const { children } = this.props
    return (
      <CustomerContext.Provider value={this.state}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={this.state.computedTheme}>
            {children}
          </ThemeProvider>
        </StylesProvider>
      </CustomerContext.Provider>
    )
  }
}

export default CustomerContext

const provider = withOktaAuth(CustomerContextProvider)

export { provider as CustomerContextProvider }
